.widget-cta a
{
  text-decoration: none;
  display: inline-flex;
  background-color: #1A7DD7;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 26px;
  align-items: center;
  height: 46px;
  padding: 10px 30px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  transition: opacity 0.3s;
}
.widget-cta .arrow
{
  padding-right: 20px;
}
.widget-cta a:hover
{
  opacity: 0.8;
}
