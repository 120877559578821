.widget-twocol > .columns
{
  display: flex;
}
.widget > .columns > .col
{
  flex: 0 1 50%;
  max-width: 50%;
  padding-right: 15px;
}
.widget-twocol > .columns > .col > *:last-child
{
  margin-bottom: 0;
}
.widget-twocol > .columns > .col + .col
{
  padding-left: 15px;
  padding-right: 0px;
}
@media(max-width: 600px)
{
  .widget-twocol > .columns
  {
    display: block;
  }
  .widget-twocol > .columns > .col
  {
    max-width: 100%;
    padding-right: 0;
  }
  .widget-twocol > .columns > .col + .col
  {
    padding-top: 20px;
    padding-left: 0;
  }
}
