/* left/right padding contentarea */
.toggle-topsearch {
  cursor: pointer;
}

.topsearch {
  background-color: #1A7DD7;
  transition: height 0.4s;
  overflow: hidden;
  height: 0;
}
html.show-topsearch .topsearch, html.searchpage .topsearch {
  height: 84px;
}
.topsearch > form {
  padding: 20px;
  position: relative;
  max-width: 770px;
  margin: 0 auto;
}
.topsearch > form > * {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 44px;
  border: 0 none;
  font-size: 16px;
}
.topsearch > form input {
  border-radius: 3px;
  width: 100%;
  padding: 12px 45px 12px 15px;
  line-height: 20px;
  background-color: #fff;
}
.topsearch > form button {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: inherit;
  vertical-align: bottom;
  display: inline-block;
  cursor: pointer;
  width: 44px;
  line-height: 32px;
  padding: 0;
  background-color: transparent;
}

.searchresults {
  margin-top: 50px;
}
.searchresults ul {
  list-style-type: none;
  padding: 30px 0 0;
  margin: 0;
}
.searchresults ul > li {
  padding-bottom: 30px;
}
.searchresults a {
  color: inherit;
  text-decoration: none;
  line-height: 170%;
}
.searchresults a .title {
  font: 700 18px/120% "Montserrat", sans-serif, Arial;
  padding-bottom: 5px;
}
.searchresults a .link {
  padding-top: 5px;
  color: #1A7DD7;
}
.searchresults a:hover .link {
  text-decoration: underline;
}
.searchresults a > * {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}