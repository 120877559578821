/* Most of the basic video styling is loaded by shared/rtd/rtd.es, @mod-publisher/js/richcontent/all */
.wh-video__playbutton {
  cursor: pointer;
  position: absolute;
  display: block;
  background-color: #0b0b0b;
  left: 50%;
  top: 50%;
  margin-left: -37px;
  margin-top: -25px;
  opacity: 0.75;
  width: 75px;
  height: 50px;
  border: 1px solid #000;
  border-radius: 12px;
  transition: opacity 0.3s;
}
.wh-video__playbutton::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 12px 20.8px;
  border-color: transparent transparent transparent #fff;
  margin: 0 auto;
  margin-top: 12px;
}

.wh-video__innerframe__preview:hover .wh-video__playbutton {
  opacity: 1;
}